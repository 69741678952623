<template>
  <div class="features-list" :class="variantClass">
    <div class="features-list__feature-item">
      <div class="features-list__feature-item__icon"></div>
      <div class="features-list__feature-item__text">
        <span class="features-list__feature-item__keyword">{{trans('searchmask.searchBoxBody.subheading')}}</span>
      </div>
    </div>
    <div class="features-list__feature-item">
      <div class="features-list__feature-item__icon"></div>
      <div class="features-list__feature-item__text">
        <span class="features-list__feature-item__keyword">{{trans('searchmask.searchBoxBody.bullet.1')}}</span>
      </div>
    </div>
    <div class="features-list__feature-item">
      <div class="features-list__feature-item__icon"></div>
      <div class="features-list__feature-item__text">
        <span class="features-list__feature-item__keyword">{{trans('searchmask.searchBoxBody.bullet.2')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { trans } from '@/filters';

export default {
  name: 'LandingPageSidelist',
  data: function () {
    return {
      showNew: false
    }
  },
  methods: {
    trans
  },
  computed: {
    variantClass() {
      return `features-list--${this.globalCobrandedVariant}`;
    }
  }
}
</script>

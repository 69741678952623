<template>
  <div class="container booking-flow__tab">
    <div class="row">
      <div class="col-xs-6 langing-page__search-column">
        <div id="fh-search-form" class="search-box search-box--primary">
          <SearchModeSwitcher :mode="searchMode" @switchMode="switchMode" />

          <div class="search-box__body">
            <!-- port inputs -->
            <div class="typeahead-container" v-for="(notused, index) in destinationsCount" :key="'typeahead-container-' + index" data-test="portInput">
              <div class="search-field-separator" v-if="index > 0"></div>
              <!-- location hint -->
              <input v-if="index === activePortIndex" class="form-control vue-typehint" tabindex="-1" v-model="activeTypehint" disabled />

              <!-- input location -->
              <input
                type="text"
                autocomplete="off"
                :spellcheck="false"
                class="form-control"
                :id="'port-input-' + index"
                :placeholder="portholders(index)"
                v-model="filters[index]"
                @focus="
                  $event.target.select();
                  onPortInputFocus(index);
                "
                @blur="onPortInputBlur(index)"
                @keyup.enter.prevent="onSearchInputKeyDown(index)"
                @keydown.tab="onSearchInputKeyDown(index)"
                data-hj-allow
                data-test="portInputText"
              />

              <!-- multitrip indicator -->
              <span v-if="searchMode === 'multi' && index < destinationsCount - 1" class="multi-trip-connection-circle">
                {{ index + 1 }}
              </span>

              <SearchSwapIconButton v-if="index === 0 && isSingleTrip" isBig :flip="flipDirection" :isDisabled="!canSwitchDirections" @click="switchPortsDirection()" />
            </div>

            <!-- add/remove trip buttons -->
            <div v-if="searchMode == 'multi'" class="stack-h-right">
              <!-- remove destination -->
              <span class="btn-label" v-if="canRemoveDestination" @click="removeDestination()">
                {{ trans('removetriplabel') }}
              </span>

              <!-- add destination -->
              <span class="btn-label" :class="{ 'btn-label--disabled': canAddDestination }" @click="addDestination()" data-test="addDestination">
                {{ trans('addtriplabel') }}
              </span>
            </div>

            <div class="fh-search-form-separator"></div>

            <!-- dates -->
            <SearchDatesInputs :dateInputsCount="dateInputsCount" :activeIndex="action === actions.dates ? activeDateIndex : -1" @dateClicked="onDateInputClick"> </SearchDatesInputs>

            <div class="fh-search-form-separator"></div>

            <!-- passengers & vehicles -->
            <div id="passengers-select-input">
              <div class="row">
                <div class="col-xs-12">
                  <TravelersCounter @onTravelerClick="onPassengersInputFocus" />
                </div>
              </div>
            </div>
          </div>
          <SearchButtonBar :isWaiting="isWaiting" :error="error" @onSearchClicked="onSearchClicked()" />
          <ToggleSwitch
            v-if="showAccommodationSwitchForDesktop && !isCobrandedPage"
            class="ml3 mt24"
            :value="isSearchingForAccommodation"
            :hasFixedHeight="false"
            :text="trans('searchmask.switch.searchAccommodation')"
            @input="setSearchingForAccommodation"
          />
        </div>
      </div>

      <div class="col-xs-6 langing-page__search-column">
        <div class="search-state-indicator hidden-xs" :class="{ highlight: !isFirstLoad }" :style="{ top: activeElementYCoordinate }"></div>

        <div class="search-box search-box--secondary landing-page-sidebox" :style="{ minHeight: formAutoHeight }">
          <!-- show default landing panel if action not performed -->
          <SearchSidebox v-if="action === actions.nothing">
            <template #header>
              <SearchSideboxHeader :isHeader="true" :title="trans('landing.panel.title')" />
            </template>

            <template #body>
              <QuickSearchComponent @performQuickSearch="performQuickSearch()" v-if="isCobrandedPage === false" />
              <LandingPageSidelist />
              <FerryhopperCopyright />
            </template>
          </SearchSidebox>

          <SearchSidebox v-if="action === actions.port">
            <template #header>
              <SearchSideboxHeader :title="selectPortTitle" infoSlug="travel-with-ferryhopper" @openInfoModalClicked="openInfoModalClicked" />
            </template>

            <template #body>
              <SearchPortSelector
                :filter="filters[activePortIndex]"
                :locations="locations"
                :locationsByAlias="locationsByAlias"
                :target="activePortIndex"
                :selections="selectedPorts"
                :placeholder="portholders(activePortIndex)"
                :previousPort="selectedPorts[activePortIndex - 1]"
                :nextPort="selectedPorts[activePortIndex + 1]"
                :searchMode="searchMode"
                @typehintUpdate="onTypehintUpdate"
                @onPortSelectionChange="onPortSelectionChange"
              />
            </template>
          </SearchSidebox>

          <SearchSidebox v-if="action === actions.dates">
            <template #header>
              <SearchSideboxHeader :title="selectDateTitle" />
            </template>

            <template #body>
              <DesktopTripDatepicker :activeDateIndex="activeDateIndex" />
            </template>
          </SearchSidebox>

          <SearchSidebox v-if="action === actions.passengers">
            <template #header>
              <SearchSideboxHeader :title="trans(`${passengerHeader}`)" :hasExtraClass="true" />
            </template>
            <template #body>
              <SearchTravelersPicker />
            </template>
          </SearchSidebox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import { nextTick } from 'vue';
import { each as _each, sortBy as _sortBy } from 'lodash-es';
import { message, notNull, getPageLanguage } from '@/logic/helpers/utils';
import SuggestionsEngine from '@/logic/managers/SuggestionsEngine';
import emitter from '@/emitter';
import { eventPortSelected, eventSearchInfoClicked, eventQuickSearch } from '@/logic/services/events/createSearchEvents';

// initialize the suggestions engine and add it to the window object
window.ferryhopper = window.ferryhopper || {};
window.ferryhopper.suggestions_engine = new SuggestionsEngine([]);

// pre-initialized list of actions
let actions = {
  nothing: 0,
  port: 1,
  dates: 2,
  passengers: 3,
  departure: 4,
};

import SearchModeSwitcher from '@/components/search/common/SearchModeSwitcher';
import SearchDatesInputs from '@/components/search/desktop/SearchDatesInputs';
import SearchSidebox from '@/components/search/desktop/SearchSidebox';
import SearchSideboxHeader from '@/components/search/desktop/SearchSideboxHeader';
import SearchPortSelector from '@/components/search/desktop/SearchPortSelector';
import SearchButtonBar from '@/components/search/desktop/SearchButtonBar';
import SearchTravelersPicker from '@/components/search/desktop/SearchTravelersPicker';
import LandingPageSidelist from '@/components/search/desktop/LandingPageSidelist';
import QuickSearchComponent from '@/components/search/desktop/QuickSearchComponent';
import FerryhopperCopyright from '@/components/search/desktop/FerryhopperCopyright';
import TravelersCounter from '@/components/shared/Travelers/TravelersCounter';
import PortsRepository from '@/logic/repositories/PortsRepository';
import SearchSwapIconButton from '@/components/search/SearchSwapIconButton';
import DatePickerSpinner from '@/components/search/common/DatePickerSpinner';
import { mapGetters } from 'vuex';
import SearchAppMixin from '@/components/search/mixins/SearchAppMixin';
import AccommodationMixin from '@/components/search/mixins/AccommodationMixin';
import { getSearchStatefromStorage } from '../../../logic/models/search/createInitialSearchState';
import { setDocumentStyle } from '@/logic/dom/helpers';
import { ga4Event } from '@/logic/services/events/EventService';
import { eventStepReached } from '@/logic/services/events/createSharedEvents';
import { trans } from '@/filters';
import { defineAsyncComponent } from 'vue';

const DesktopTripDatepicker = defineAsyncComponent({
  loader: () => import('@/components/shared/Datepickers/DesktopTripDatepicker'),
  loadingComponent: DatePickerSpinner,
});

export default {
  name: 'DesktopSearchApp',
  components: {
    SearchModeSwitcher,
    SearchDatesInputs,
    SearchSidebox,
    SearchSideboxHeader,
    SearchPortSelector,
    SearchButtonBar,
    SearchTravelersPicker,
    LandingPageSidelist,
    QuickSearchComponent,
    SearchSwapIconButton,
    FerryhopperCopyright,
    DesktopTripDatepicker,
    TravelersCounter,
    DatePickerSpinner,
  },
  mixins: [SearchAppMixin, AccommodationMixin],
  data() {
    return {
      // active action (eg. selecting port, date, passengers)
      action: 0,
      // list of possible actions
      actions: actions,
      // current active location
      activePortIndex: 0,
      // all available locations sorted by popularity
      locations: [],
      // all available locations sorted by alias
      locationsByAlias: [],
      // active date
      activeDateIndex: 0,
      // filter per location input
      filters: ['', '', '', '', ''],
      // typehint for currently active location input
      activeTypehint: '',
      // distance of active element to top of form
      activeElementTop: 30,
      formHeight: 430,
      // the currently active element
      activeElementId: 'fh-search-mode-switcher',
      isWaiting: false,
      flipDirection: false,
    };
  },
  created() {
    // initialize array of available locations
    const allPorts = PortsRepository.getAllPorts();
    this.locations = allPorts;
    // case-insensitive sorting based on language without punctuation
    this.locationsByAlias = allPorts.sort((a, b) => a.alias.toLowerCase().localeCompare(b.alias.toLowerCase(), getPageLanguage(), { ignorePunctuation: true }));

    this.filters = this.selectedPorts.map((p) => (p && p.alias) || '');

    emitter.$on('dateSelectedInCalendar', () => {
      this.updateArrowPosition();
    });

    if (this.isAutoSearch || !this.isActiveTabSearch) return;
    eventStepReached();
  },
  mounted() {
    emitter.$on('onFirstResultCollected', () => (this.isWaiting = false));

    this.updateArrowPosition();

    if (this.isAutoSearch) {
      this.onSearchClicked();
      this.$store.commit('searchModule/setAutoSearch', false);
    }
  },
  computed: {
    ...mapGetters({
      selectedDates: 'searchModule/getSelectedDates',
      destinationsCount: 'searchModule/destinationsCount',
      canAddDestination: 'searchModule/canAddDestination',
      canRemoveDestination: 'searchModule/canRemoveDestination',
      passengersString: 'searchModule/passengersString',
      vehiclesString: 'searchModule/vehiclesString',
      selectedPorts: 'searchModule/selectedPorts',
    }),
    dateInputsCount() {
      return this.searchMode === 'multi' ? this.tripsCount : 2;
    },
    activeElementYCoordinate() {
      return `${this.activeElementTop}px`;
    },
    formAutoHeight() {
      return `${this.formHeight}px`;
    },
    selectPortTitle() {
      if (this.searchMode === 'single') {
        return this.activePortIndex === 0 ? message('departureporttitle') : message('destinationporttitle');
      } else {
        return message('multiselectlocation' + (this.activePortIndex + 1));
      }
    },
    selectDateTitle() {
      if (this.searchMode === 'single') {
        return this.activeDateIndex === 0 ? message('depdate') : message('retdate');
      } else {
        return `${message('multidateholder')} ${this.activeDateIndex + 1}`;
      }
    },
    isFirstLoad() {
      return this.$store.state.navigationModule.initialLoad;
    },
    canSwitchDirections() {
      const hasSelectedSomePort = this.filters[0] !== '' || this.filters[1] !== '';
      return this.searchMode === 'single' && hasSelectedSomePort;
    },
    isSingleTrip() {
      return this.searchMode === 'single';
    },
    passengerHeader() {
      return this.isPetsBookingActivated ? 'searchmaskpassengerpetstitle' : 'searchmaskpassengertitle';
    },
  },
  methods: {
    trans,
    async performQuickSearch() {
      const savedSearch = getSearchStatefromStorage();
      await this.$store.dispatch('searchModule/initializeSearch', savedSearch);

      // Filters are only used in the desktop version
      // Replace the filters. Setting the filters property directly, triggers all other properties that listen on this
      // to react, e.g. the `canSwitchDirections` computed.
      // Vue's reactivity won't pick up an array element's value being changed, because it will still refer to the same array.
      // Setting the entire array changes the reference and triggers the reactivity system.

      this.filters = this.selectedPorts.map((p) => (p && p.alias) || '');

      this.updateArrowPosition();
      // switch to ports in order not to show previous search again
      this.action = actions.port;
      this.onSearchClicked();

      eventQuickSearch(this.searchMode, this.searchQueries);
    },
    //------------------------------------------------------------------------
    onTypehintUpdate(hint) {
      this.activeTypehint = hint;
    },
    //------------------------------------------------------------------------
    //
    async onDateInputClick(index) {
      this.activeElementId = 'date-input-' + index;
      this.updateArrowPosition();
      this.activeDateIndex = index;
      this.action = actions.dates;
    },
    //------------------------------------------------------------------------
    //
    addDestination() {
      this.$store.dispatch('searchModule/addTrip');
      emitter.$emit('onSearchParametersChange');
      this.updateArrowPosition();
      ga4Event('hopping_add_trip_clicked', {});
    },
    //------------------------------------------------------------------------
    //
    removeDestination() {
      if (!this.canRemoveDestination) return;

      this.$store.dispatch('searchModule/removeTrip');

      // with this check, we ensure that the active element id is not set
      // to a port input that has been removed from DOM
      if (this.activePortIndex > this.destinationsCount - 1) {
        this.activePortIndex = this.destinationsCount - 1;
        if (this.activeElementId.startsWith('port-input-')) {
          this.activeElementId = `port-input-${this.activePortIndex}`;
        }
      }

      if (this.activeDateIndex > this.$store.state.searchModule.tripsCount - 1) {
        this.activeDateIndex = this.$store.state.searchModule.tripsCount - 1;
        if (this.activeElementId.startsWith('date-input-')) {
          this.activeElementId = `date-input-${this.activeDateIndex}`;
        }
      }

      this.updateArrowPosition();
      emitter.$emit('onSearchParametersChange');
    },
    switchPortsDirection() {
      if (!this.canSwitchDirections) return;

      this.flipDirection = !this.flipDirection;
      const originPort = this.selectedPorts[0];
      const destinationPort = this.selectedPorts[1];
      this.$store.dispatch('searchModule/setSelectedPort', { port: destinationPort, portIndex: 0 });
      this.$store.dispatch('searchModule/setSelectedPort', { port: originPort, portIndex: 1 });
      this.filters = this.selectedPorts.map((p) => (p && p.alias) || '');
      ga4Event('swap_ports_clicked', {});
    },
    //------------------------------------------------------------------------
    //
    switchMode(searchMode) {
      // update global state
      if (searchMode === 'multi') {
        this.$store.dispatch('searchModule/setTripModeMulti');
      }

      // update global state
      if (searchMode === 'single') {
        this.$store.dispatch('searchModule/setTripModeSingle');

        if (this.activePortIndex > 1) {
          this.activePortIndex = 1;
          // with this check, we ensure that the active element id is not set
          // to a port input that has been removed from DOM
          if (['port-input-2', 'port-input-3', 'port-input-4'].includes(this.activeElementId)) {
            this.activeElementId = 'port-input-1';
          }
        }

        switch (this.action) {
          case actions.port:
            this.onPortInputFocus(this.activePortIndex);
            break;
        }
      }

      // wait for component re-render and then update the arrow (this will
      // help avoid updating the arrow position before the form has been
      // scaled up/down)
      this.updateArrowPosition();
      // emit change event
      emitter.$emit('onSearchParametersChange');
    },
    //------------------------------------------------------------------------
    //
    onPortSelectionChange(selection, portIndex, portLocationCategory, locationIndex) {
      let port = this.locations.find((location) => location.LocationAbbr === selection);

      if (notNull(port)) {
        this.filters[portIndex] = port.alias;
        this.$store.dispatch('searchModule/setSelectedPort', { port, portIndex });
        eventPortSelected({
          port_code: selection,
          departure_or_destination: portIndex === 0 ? 'departure' : 'destination',
          type_of_search: this.searchMode,
          user_action: portLocationCategory,
          port_position: locationIndex + 1,
        });
      } else {
        // clear port selection when the requested port is not found
        this.$store.dispatch('searchModule/setSelectedPort', { port: { short: '' }, portIndex });
      }

      this.updateArrowPosition();
      emitter.$emit('onSearchParametersChange');
    },
    //------------------------------------------------------------------------
    //
    selectedPortNames(index) {
      if (typeof this.selectedPorts[index] === 'undefined') {
        return '';
      }
      return this.selectedPorts[index] && this.selectedPorts[index].alias;
    },
    //------------------------------------------------------------------------
    // returns placeholders for location inputs
    portholders(index) {
      switch (this.searchMode) {
        case 'multi':
          return message(`multiplaceholder${index + 1}`);
        case 'single':
          return index === 0 ? message('depplaceholder') : message('arrplaceholder');
      }
    },
    //------------------------------------------------------------------------
    // called whent he user clicks on a location input
    onPortInputFocus(index) {
      this.activeElementId = 'port-input-' + index;
      this.updateArrowPosition();
      this.activePortIndex = index;
      this.action = actions.port;
      emitter.$emit('onPortInputFocus', this.selectedPorts[this.activePortIndex]);
    },
    //------------------------------------------------------------------------
    // called each time the user types something
    onSearchInputKeyDown(index) {
      emitter.$emit('onSearchInputKeyDown', index);
    },
    //------------------------------------------------------------------------
    //	called when the input is lost on a location input
    onPortInputBlur(index) {
      if (this.filters[index] !== '') {
        emitter.$emit('onPortInputBlur', index);
      }
    },
    //------------------------------------------------------------------------
    // called when the user focus on passenger or vehicle inputs
    onPassengersInputFocus() {
      this.activeElementId = 'passengers-select-input';
      this.action = actions.passengers;
      this.updateArrowPosition();
      ga4Event('ticket_control_options_opened', {});
    },
    //------------------------------------------------------------------------
    // updates the position of the indicator (arrow), always on nextTick in
    // order for the form to properly re-render after a status change
    updateArrowPosition() {
      nextTick(() => {
        try {
          // get the rendered height of search form
          let formElement = document.querySelector('#fh-search-form').getBoundingClientRect();
          this.formHeight = formElement.height;
          setDocumentStyle('--search-ports-h-unit', `${formElement.height}px`);
          // get the active elements distance from the top of the form
          let activeElement = document.querySelector(`#${this.activeElementId}`).getBoundingClientRect();
          let yElement = activeElement.top;
          let yForm = formElement.top;
          let hElement = activeElement.height / 2;
          let arrowHeight = 30;
          let arrowTopPosition = yElement + hElement - yForm - arrowHeight / 2;
          this.activeElementTop = arrowTopPosition > 30 ? arrowTopPosition : 30;
        } catch (exception) {
          // silently handled exception as this functionality is not critical
          // this exception can be raised when the active element id is not
          // present on the current document, eg. with aegean air that we do
          // not show the searchMode switcher (initial active element)
        }
      });
    },
    //------------------------------------------------------------------------
    //
    openInfoModalClicked() {
      eventSearchInfoClicked(this.searchMode);
    },
  },
};
</script>
